import Swal from 'sweetalert2'

export const SweetAlert = Swal.mixin({
  showClass: {
    popup: '', // disable popup bounce animation
  },
  hideClass: {
    popup: '', // disable popup bounce animation
  },
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-danger ms-1',
  },
  buttonsStyling: false,
})
