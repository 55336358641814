import { useContext } from 'react'

import Cookie from 'js-cookie'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Power } from 'react-feather'
import { FaUserAlt } from 'react-icons/fa'
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

import * as defaultAvatar from '@/assets/images/avatars/avatar-blank.png'
import { UserContext } from '@/contexts/user'
import { deleteLogout } from '@/utils/api/authentication'

const UserDropDown = () => {
  const router = useRouter()

  const handleLogout = () => {
    deleteLogout().finally(() => {
      Cookie.remove('token')
      Cookie.remove('user')
      router.push('/')
    })
  }

  const { user, employee } = useContext(UserContext)

  return (
    <UncontrolledDropdown className="dropdown-user nav-item" tag="li">
      <DropdownToggle className="nav-link dropdown-user-link" href="/" onClick={(e) => e.preventDefault()} tag="a">
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">{user.first_name}</span>
          <span className="user-status">{user.email}</span>
        </div>
        <Image alt="User avatar" height={40} src={defaultAvatar} width={40} />
      </DropdownToggle>
      <DropdownMenu end>
        <Link href={`/employees/${employee?.slug}`} passHref>
          <DropdownItem className="w-100">
              <FaUserAlt className="me-75" size={14} />
              <span className="align-middle">Profile</span>
          </DropdownItem>
        </Link>
        <DropdownItem
          onClick={() => {
            handleLogout()
          }}
          tag="div"
        >
          <Power className="me-75" size={14} />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropDown
