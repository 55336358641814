import { createContext } from 'react'

import { UsersMe } from '@/utils/api/users'

export const initialUser: UsersMe = {
  account: {
    logo: '',
    name: '',
  },
  user: {
    first_name: '',
    last_name: '',
    email: '',
  },
  employee: {
    id: null,
    display_name: '',
    slug: null,
  },
  permission: {},
  time_zone_id: 'America/Chicago',
}

export const UserContext = createContext(initialUser)
