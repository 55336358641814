/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react'
import type { AppProps } from 'next/app'
import 'core/scss/react/pages/page-authentication.scss'
import 'core/scss/core.scss'
import 'core/scss/base/core/menu/menu-types/vertical-menu.scss'
import 'core/scss/base/core/menu/menu-types/vertical-overlay-menu.scss'
import 'core/scss/react/libs/react-select/_react-select.scss'
import 'core/components/ui-loader/ui-loader.scss'
import '@/assets/scss/style.scss'
import { useRouter } from 'next/router'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import UserLayout from '@/components/layouts/user_layout'
import { routes } from '@/components/routes'
import Progress from '@/utils/progress'
import styled from 'styled-components'
import 'nprogress/nprogress.css' // styles of nprogress

Progress()

// const Styled = styled.div`
  // scrollbar-color: light;

  // *::-webkit-scrollbar {
    // width: 7px;
    // height: 7px;
  // }
  // *::-webkit-scrollbar-track {
    // border-radius: 10px;
  // }
  // *::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    // background-color: #ebe9f1 !important;
  // }

  // ::-webkit-scrollbar {
    // width: 7px;
    // height: 7px;
  // }
  // ::-webkit-scrollbar-track {
    // border-radius: 10px;
  // }
  // ::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    // background-color: #ebe9f1 !important;
  // }
// `
const Styled = styled.div`

`

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    })
  })

  const router = useRouter()
  const { route } = router

  const signInPages = ['/sign-in', '/forgot-password', '/setup-password/[token]']
  const layoutProps = routes[route]

  const isSignIn = signInPages.includes(route)
  const isError = route.includes('error')

  return (
    <QueryClientProvider client={queryClient}>
      <div className="rootApp" id="root">
        <Styled>
          {isSignIn || isError ? (
            <Component {...pageProps} />
          ) : (
            <UserLayout {...layoutProps}>
              <Component {...pageProps} />
            </UserLayout>
          )}
        </Styled>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
