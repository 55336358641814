import classnames from 'classnames'
import Image from 'next/image'
import { GitPullRequest, Users, DollarSign } from 'react-feather'
import { BiGasPump } from 'react-icons/bi'
import { BsTruckFlatbed, BsWrench } from 'react-icons/bs'
import { FaTrailer, FaCarCrash, FaMoneyCheckAlt } from 'react-icons/fa'
import { ImUserTie } from 'react-icons/im'
import { IoGitNetwork } from 'react-icons/io5'
import { RiSteering2Fill } from 'react-icons/ri'
import { TbZoomMoney } from 'react-icons/tb'

import MenuHeader from './menu_header'
import MenuItem from './menu_item'

const Sidebar: React.FC = () => {
  return (
    <div className={classnames('main-menu menu-fixed menu-accordion menu-shadow menu-light menu-expanded')}>
      <div className="navbar-header mt-1">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item me-auto">
            <span className="brand-logo mt-3">
              <a href="/" title="Home">
                <Image alt="logo" height={30} loading="eager" priority src="/keencargo.png" width="100%" />
              </a>
            </span>
          </li>
        </ul>
      </div>
      <div className="scrollbar-container main-menu-content ps ps--active-y">
        <ul className="navigation navigation-main">
          <MenuHeader style={{ marginTop: 0 }} title="Business" />

          <MenuItem icon={<GitPullRequest />} path="/loads" title="Loads" />
          <MenuItem icon={<Users />} path="/customers" title="Customers" />
          <MenuItem icon={<IoGitNetwork />} path="/vendors" title="Vendors" />

          <MenuHeader title="Equipment" />

          <MenuItem icon={<BsTruckFlatbed />} path="/trucks" title="Trucks" />
          <MenuItem icon={<FaTrailer />} path="/trailers" title="Trailers" />

          <MenuHeader title="Accounting" />

          <MenuItem icon={<DollarSign />} path="/billing" title="Billing" />
          <MenuItem icon={<FaMoneyCheckAlt />} path="/payrolls" title="Payrolls" />
          <MenuItem icon={<BiGasPump />} path="/fuel" title="Fuel" />
          <MenuItem icon={<TbZoomMoney />} path="/adjustments" title="Adjustments " />

          <MenuHeader title="Staff" />

          <MenuItem icon={<RiSteering2Fill />} path="/drivers" title="Drivers" />
          <MenuItem icon={<ImUserTie />} path="/employees" title="Employees" />
        </ul>
      </div>
    </div>
  )
}

export default Sidebar

// <MenuItem icon={<BsWrench />} path="/maintenance" title="Maintenance" />
// <MenuItem icon={<FaCarCrash />} path="/accidents" title="Accidents" />
