type LayoutProps = {
  pageTitle: string
  containerBoxed?: boolean
}

type Props = {
  [route: string]: LayoutProps
}

export const routes: Props = {
  '/drivers': {
    pageTitle: 'Drivers',
  },
  '/drivers/new': {
    pageTitle: 'Add Driver',
    containerBoxed: true,
  },
  '/drivers/[slug]': {
    pageTitle: 'Driver',
  },
  '/employees/new': {
    pageTitle: 'Add Employee',
    containerBoxed: true,
  },
  '/employees': {
    pageTitle: 'Employees',
  },
  '/employees/[slug]': {
    pageTitle: 'Employee',
  },
  '/fuel': {
    pageTitle: 'Fuel',
  },
  '/loads': {
    pageTitle: 'Loads',
  },
  '/loads/new': {
    pageTitle: 'Add Load',
    containerBoxed: true,
  },
  '/customers': {
    pageTitle: 'Customers',
  },
  '/customers/new': {
    pageTitle: 'Add Customer',
    containerBoxed: true,
  },
  '/vendors': {
    pageTitle: 'Vendors',
  },
  '/trucks': {
    pageTitle: 'Trucks',
  },
  '/trucks/new': {
    pageTitle: 'Add Truck',
  },
  '/trailers': {
    pageTitle: 'Trailers',
  },
  '/maintenance': {
    pageTitle: 'Maintenance',
  },
  '/accidents': {
    pageTitle: 'Accidents',
  },
  '/billing': {
    pageTitle: 'Billing',
  },
  '/payrolls': {
    pageTitle: 'Payrolls',
  },
  '/payrolls/[slug]': {
    pageTitle: 'Payroll',
  },
}
