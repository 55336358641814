import classnames from 'classnames'
import Proptypes from 'prop-types'
import { Spinner } from 'reactstrap'

const UILoader = (props) => {
  const { children, blocking, loader, className, tag, overlayColor } = props

  const Tag = tag

  return (
    <Tag className={classnames('ui-loader', { [className]: className, show: blocking })}>
      {children}
      {blocking ? (
        <>
          <div
            className="overlay" /*eslint-disable */
            {...(blocking && overlayColor ? { style: { backgroundColor: overlayColor } } : {})}
          />
          <div className="loader">{loader}</div>
        </>
      ) : null}
    </Tag>
  )
}

export default UILoader

UILoader.defaultProps = {
  tag: 'div',
  blocking: false,
  loader: <Spinner color="primary" />,
}

UILoader.propTypes = {
  tag: Proptypes.string,
  loader: Proptypes.any,
  className: Proptypes.string,
  overlayColor: Proptypes.string,
  blocking: Proptypes.bool.isRequired,
}
