import classnames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface Props {
  path: string
  title: string
  icon: JSX.Element
}

const MenuItem: React.FC<Props> = ({ title, path, icon }: Props) => {
  const router = useRouter()

  return (
    <li className={classnames('nav-item', { active: router.pathname.startsWith(path) })}>
      <Link href={path} passHref>
        <a className="d-flex align-items-center" href="/">
          {icon}
          {title}
        </a>
      </Link>
    </li>
  )
}

export default MenuItem
