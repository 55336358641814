/* eslint-disable react-hooks/exhaustive-deps */
// ** React Imports
import { useEffect, useState } from 'react'

import classnames from 'classnames'
import Cookie from 'js-cookie'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { Toaster } from 'react-hot-toast'

import { UserContext, initialUser } from '@/contexts/user'
import { getUsersMe, UsersMe } from '@/utils/api/users'
import LoadingSpinner from 'core/components/spinner/Loading-spinner'
import UILoader from 'core/components/ui-loader'

import NavbarComponent from './user-layout/navbar/navbar_component'
import Sidebar from './user-layout/sidebar/sidebar'

import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
  children: JSX.Element
  containerBoxed?: boolean
  pageTitle?: string
}

const UserLayout: React.FC<Props> = ({ children, containerBoxed, pageTitle }: Props) => {
  // ** States
  const [isMounted, setIsMounted] = useState(false)
  const [hasToken, setHasToken] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [currentUser, setCurrentUser] = useState<UsersMe>(initialUser)
  const [title, setPageTitle] = useState(pageTitle)

  const router = useRouter()

  // ** Hooks
  // const { skin } = useSkin()
  // const skin = 'light'

  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if (pageTitle) {
      setPageTitle(`${pageTitle} - Treload`)
    } else {
      setPageTitle('Treload')
    }
  }, [pageTitle])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth)
    }
  }, [])

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('resize', handleWindowWidth)
    }
  }, [windowWidth])

  useEffect(() => {
    if (menuVisibility && windowWidth < 1367) {
      setMenuVisibility(false)
    }
  }, [windowWidth])

  useEffect(() => {
    if (!Cookie.get('token')) {
      router.push('/sign-in')
    } else {
      setHasToken(true)
      setIsMounted(true)
    }
  }, [])

  useEffect(() => {
    if (menuVisibility && windowWidth < 1367) {
      setMenuVisibility(false)
    }
  }, [router?.pathname])

  useEffect(() => {
    if (!hasToken) {
      return
    }

    if (!Cookie.get('user')) {
      getUsersMe()
        .then((resp) => {
          const user = resp.data
          const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000)
          Cookie.set('user', JSON.stringify(user), { expires: inFifteenMinutes })
          setCurrentUser(user)
        })
        .catch(() => {
          // TODO
        })
    } else {
      const user = JSON.parse(Cookie.get('user') || '')
      setCurrentUser(user)
    }
  }, [hasToken])

  if (!isMounted) {
    return <UILoader blocking loader={<LoadingSpinner />} />
  }

  const navbarType = 'sticky' // floating, sticky, static, hidden
  const footerType = 'static' // sticky, static, hidden

  const navbarWrapperClasses = {
    floating: 'navbar-floating',
    sticky: 'navbar-sticky',
    static: 'navbar-static',
    hidden: 'navbar-hidden',
  }

  const footerClasses = {
    static: 'footer-static',
    sticky: 'footer-fixed',
    hidden: 'footer-hidden',
  }

  const menuCollapsed = false
  // const menuVisibility = false
  const isHidden = false

  return (
    <UserContext.Provider value={currentUser}>
      <div
        className={classnames(
          `wrapper vertical-layout ${navbarWrapperClasses[navbarType] || 'navbar-floating'} ${
            footerClasses[footerType] || 'footer-static'
          }`,
          {
            // Modern Menu
            'vertical-menu-modern': windowWidth >= 1367,
            'menu-collapsed': menuCollapsed && windowWidth >= 1367,
            'menu-expanded': !menuCollapsed && windowWidth > 1367,

            // Overlay Menu
            'vertical-overlay-menu': windowWidth < 1367,
            'menu-hide': !menuVisibility && windowWidth < 1367,
            'menu-open': menuVisibility && windowWidth < 1367,
          },
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isHidden ? { 'data-col': '1-column' } : {})}
      >
        <Head>
          <link href="/1.svg" rel="icon" />
          <title key="page-title">{title}</title>

          <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        </Head>

        <Script
          async
          defer
          src="https://widget.usersnap.com/global/load/de167a52-dfe3-4f0f-8fd0-dbaf04f586d4?onload=onUsersnapCXLoad"
        />
        {/* eslint-disable-next-line @next/next/inline-script-id */}
        <Script
          dangerouslySetInnerHTML={{
            __html: `
              window.onUsersnapCXLoad = function(api) {
                // store the Usersnap global api on the window, if case you want to use it in other contexts
                window.Usersnap = api
                api.init({ custom: { email: '${currentUser?.user?.email}', userId: '${currentUser?.user?.id}' } })
            }
            `,
          }}
        />
        <Sidebar />
        <NavbarComponent menuVisibility={menuVisibility} setMenuVisibility={setMenuVisibility} />
        <div className="app-content content overflow-hidden">
          <div
            className={classnames('content-wrapper p-0 animate__animated animate__fadeIn', {
              'container-xxl': containerBoxed,
            })}
          >
            <Toaster />
            {children}
          </div>
        </div>
        {/* Vertical Nav Menu Overlay */}
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        <div
          className={classnames('sidenav-overlay', { show: menuVisibility })}
          onClick={() => setMenuVisibility(false)}
        />
        {/* eslint-enable jsx-a11y/no-static-element-interactions */}
        {/* eslint-enable jsx-a11y/click-events-have-key-events */}
        {/* Vertical Nav Menu Overlay */}
      </div>
    </UserContext.Provider>
  )
}

export default UserLayout
