/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'

import API from '../api'

export const login = async (email: string, password: string): Promise<AxiosResponse<null>> => {
  return API.post<null>('/v1/login', { user: { email, password } })
}

export const postForgotPassword = async (email: string): Promise<AxiosResponse<null>> => {
  return API.post<null>('/v1/reset_password', { email })
}

export const postSetupPassword = async (
  password: string,
  password_confirmation: string,
  token: string,
): Promise<AxiosResponse<null>> => {
  return API.post<null>('/v1/setup_password', { password, password_confirmation, token })
}

export const deleteLogout = async (): Promise<AxiosResponse<null>> => {
  return API.delete<null>('/v1/logout')
}
