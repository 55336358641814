import Router from 'next/router'
import NProgress from 'nprogress'

NProgress.configure({ showSpinner: false, trickleSpeed: 1000, speed: 1000 })

const Progress = (): void => {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
}

export default Progress
