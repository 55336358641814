interface Props {
  title: string
  style?: object
}

const MenuHeader: React.FC<Props> = ({ title, style }: Props) => {
  return (
    <li className="navigation-header" style={style}>
      <span>{title}</span>
    </li>
  )
}

export default MenuHeader
