import axios from 'axios'
import Cookie from 'js-cookie'

import { getEnv } from '@/utils/env'
import { SweetAlert } from '@/utils/sweetlalert'
import {NotFound} from '@/components/pages/not_found'

let baseURL = null
switch (getEnv()) {
  case 'staging':
    baseURL = 'https://staging-api.keencargotms.com/api'
    break
  case 'development':
    if (process?.env?.NEXT_PUBLIC_LOCAL_API_ADDRESS) {
      baseURL = process?.env?.NEXT_PUBLIC_LOCAL_API_ADDRESS
    } else {
      baseURL = 'http://localhost:3000/api'
    }

    break
  case 'production':
    baseURL = 'https://api.keencargotms.com/api'
    break
  default:
    baseURL = 'http://localhost:3000/api'
}

const instance = axios.create({
  baseURL,
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      Cookie.remove('token')
      window.location.pathname = '/sign-in'
    }

    if (error.response.status === 403) {
      SweetAlert.fire({
        title: 'Access Denied',
        icon: 'error',
        text: "You don't have permission to perform this action.",
      })
    }

    return Promise.reject(error)
  },
)

const token = Cookie.get('token')

if (token) {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`
}

export default instance
