import classnames from 'classnames'
import { Menu } from 'react-feather'
import { NavItem, NavLink, Navbar } from 'reactstrap'

import UserDropDown from './user_dropdown'

interface Props {
  setMenuVisibility: (isVisible: boolean) => void
  menuVisibility: boolean
}

const NavbarComponent: React.FC<Props> = ({ setMenuVisibility, menuVisibility }: Props) => {
  return (
    <Navbar
      className={classnames('header-navbar navbar align-items-center navbar-sticky navbar-shadow')}
      container={false}
      expand="lg"
      light
    >
      <div className="navbar-container d-flex content">
        <ul className="navbar-nav d-xxl-none">
          <NavItem className="mobile-menu me-auto d-flex align-items-center">
            <NavLink className="nav-menu-main menu-toggle hidden-xs is-active">
              <Menu className="ficon" onClick={() => setMenuVisibility(!menuVisibility)} />
            </NavLink>
          </NavItem>
        </ul>

        <ul className="nav navbar-nav align-items-center ms-auto">
          <UserDropDown />
        </ul>
      </div>
    </Navbar>
  )
}

export default NavbarComponent
